/* Free Item Top Bar Change CSS START */

body {
    overflow-x: hidden !important;
}

div#fsb_background {
    background-color: rgb(30, 30, 32);
}

div#fsb_bar, div#fsb_fi_bar {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

div#fsb_container[class~="anim-hide"]>div#fsb_background>div#fsb_bar,
div#fsb_fi_container[class~="anim-hide"]>div#fsb_fi_background>div#fsb_fi_bar{
    transform: translate(200%, 0);
    opacity: 0;
}

div#fsb_container[class~="anim-hidden"]>div#fsb_background>div#fsb_bar,
div#fsb_fi_container[class~="anim-hidden"]>div#fsb_fi_background>div#fsb_fi_bar {
    transform: translate(-200%, 0);
    opacity: 0;
}

/* Free Item Top Bar Change CSS END */